.footer {
    background-color: white;
    padding: 20vh 7vh 7vh 7vh;
    text-align: left;
}

#footer-logo {
    font-size: 22px;
    color: black;
}

#footer-logo1 {
    font-size: 38px;
    font-weight: bolder;
    letter-spacing: 5px;
    color: black;
}

#footer-logo2 {
    font-weight: 200;
    font-size: 20px;
}

#footer-logo3 {
    font-weight: 100;
    font-size: 15px;
    padding-top: 10px;
}


.footer-contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
}

#footer-contact {
    color: black;
    font-size: 15px;
    font-weight: 100;
}

.footer-icons {
    display: flex;
    /* justify-content: center; */
    gap: 40px;
}

#footer-icons {
    font-size: 30px;
    color: black;
    padding-bottom: 12px;
}

@media only screen and (max-width: 440px){
    .footer {
        padding: 15vh 2vh 2vh 2vh;
    }

    .footer-contact {
        display: flex;
        flex-direction: column;
    }
}