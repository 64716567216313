.navbar1 {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    display: none;
  }

  .menu-bars {
    margin-right: 2rem;
    font-size: 2rem;
    background: none;
    
  }
  
  .nav-menu {
    background-color: black;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    /* bottom: -100%; */
    transition: 850ms;
    z-index: 9999;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 8px 0px 8px 0px;
    list-style: none;
    height: 60px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: white;
    font-size: 35px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
  }
  
  .nav-text a:hover {
    background-color: white;
    color: black;

  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  #x-button {
    background-color: white;
    border-radius: 20px;
  }

  .navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .nav-menu-items {
    margin-left: -40px;
  }

  @media only screen and (max-width: 1075px){
    .navbar1 {
        height: 69px;
        display: flex;
        justify-content: start;
        align-items: center;
        visibility: visible;
      }
    
    .pages {
        display: none;
    }

    .about {
        flex-direction: column
    }

    .about-paragraph {
        padding: 7vh 0vh 0vh 0vh;
    }

    .about-picture {
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }
}

@media only screen and (max-width: 480px){
  .nav-menu.active {
  }
}