.navbar {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 7vh 7vh 10vh 7vh;
  }

  a:link {
    text-decoration: none!important;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }

  #logo {
    color: black;
    /* min-width: 200px; */
    text-align: center;
    font-size: 30px;
    letter-spacing: 10px;
  }

  #logo2 {
    color: black;
    font-size: 15px;
  }

  #icon {
    width: 80px;
    padding-top: 5px;
    padding-right: 12px;
  }
  
  .pages {
    display: flex;
    padding-left: 15vh;
    gap: 2rem;
    font-size: 15px;
  }
  
  #pages {
    text-decoration: none;
    color: black;
    font-weight: 100;
    font-size: 30px;
    transition: transform .15s ease-in-out, box-shadow 0.25s ease-in-out;
  }

  #pages:hover {
    transform: scale(1.01);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }


  @media only screen and (max-width: 440px){

      .navbar {
        padding: 3vh 3vh 3vh 3vh;
      }

      #logo {
        font-size: 20px;
      }

      #icon {
        width: 50px;
      }

      .menu-bars {
        margin-right: 0rem !important;
      }
  }
