.contact-page {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding: 7vh 7vh 0vh 7vh;
}

#contact-input {
    padding-bottom: 10px;
}

#contact-input2 {
    padding: 2vh;
    font-size: 20px;
    
}

input {
    padding: 2vh;
    width: 200px;
    font-size: 20px;
}

.input-boxes {

}

button {
    border-color: black;
    width: 120px;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    transition: transform .15s ease-in-out, box-shadow 0.25s ease-in-out;
}

#button1:hover {
    /* background-color: #212528; */
    cursor: pointer;
    transform: scale(1.01);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.contact_buttons {
    display: flex;
    align-items: center;
    gap: 13px;
}

#email_button {
    width: 200px;
}

.virtual {
    padding-bottom: 5vh;
    padding-top: 5vh;
}


.virtual-child {
    display: flex;
    gap: 12px;
}


#virtual_consult {
    border-color: black;
    width: 200px;
    border-spacing: 10px;
    padding: 10px;
    background-color: black;
    text-shadow: none;
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 14px;
    text-align: center;
}

#virtual_consult:hover {
    background-color: rgb(47, 45, 45);
}

#icon-button {
    width: 80px;
    border-radius: 5px;
}

.virtual-dashboard {

}

.virtual-dashboard-hidden {
    display: none;
}

.virtual-options-contanier {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.virtual-options {
    max-width: 350px;
    padding: .6vh 2vh 1vh 2vh;
    border: solid;
    border-radius: 5px;
    border-color: rgb(176, 176, 176);
}

.virtual-options2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.virtual-options3 {
    display: flex;
    align-items: center;
    gap: 10px;
}

#more-info {
    text-decoration: underline
    ;
    text-decoration-color: rgb(46, 119, 222);
    color: rgb(46, 119, 222);
    font-weight: 400;
}

#more-info:hover {
    cursor: pointer;
}

#virtual-option-check {
    width: 20px;
    height: 20px;
}

.flex-spacing {
    display: flex;
    align-items: center;
    padding: 0vh 2vh 2vh 0vh;
    gap: 10px;
}

.flex-spacing-v {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 7vh 7vh 0vh 7vh;
    gap: 10px;
}

#check {
    width: 15px;
    height: 15px;
}

label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

@media only screen and (max-width: 440px){
    .contact-page {
        padding: 3vh 2vh 1vh 2vh;
    }

    #virtual_consult {
        font-size: 12px;
    }

    .flex-spacing-v {
        padding: 2vh 2vh 1vh 2vh;
    }
}