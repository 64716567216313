.services {
    display: flex;
    padding: 7vh;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
}

h3.services {
    white-space: pre;
}

.services-pic {
    display: flex;
    justify-content: center;
}

#services-pic {
    max-width: 100vh;
}

.services-header {
    text-align: center;
    padding: 7vh 1vh 7vh 1vh;
}

.services-equipment {
    display: grid;
    min-width: 300px;
    gap: 10px;
}

#list {
    font-size: 19px;
    font-weight: 100;
    padding-top: 10px;
}

.services-bottom {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;

}

.neck-img {
    padding: 7vh 7vh 0vh 7vh;
}

#neck {
    object-fit: contain;
    border-radius: 20px;
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (max-width: 900px) {
    .services-bottom {
        flex-direction: column;
    }

    .services-equipment {
        order: 2;
    }

    .neck-img {
        padding: 7vh 7vh 7vh 0vh;
    }
}


@media only screen and (max-width: 440px){
    .services {
        padding: 2vh;
    }

    #services-pic {
        max-width: 100%;
    }

    .services-header {
        padding: 3vh 1vh 3vh 1vh;
    }

    .neck-img {
        padding: 3vh 3vh 3vh 0vh;
    }
}