.review-container {
    max-width: 1000px;
    background-color: #49A2D5;
}

#arrow-left {
    min-width: 30px;
}

#arrow-left:hover {
    cursor: pointer;
}

.c-reviews {
    display: flex;
    flex-direction: column;
    position: relative;
    /* box-shadow: 0px 0px 7px black; */
    padding: 7vh;
    
}

.c-review-top {
    text-align: center;
    position: relative;
    top: 2.4rem;
    color: white;
}

.c-review-top-hidden {
    display: none;
}

.c-reviews-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3vh;
    color: white;
}

.c-review {
    padding: 5vh;
}

.c-review-hidden {
    display: none;
}

.arrows {
    color: white;
    text-align: center;
    padding-bottom: 5vh;
}

.indicators {
    display: flex;
    justify-content:center;
    /* align-items: center; */
    /* position: absolute; */
    /* bottom: 4rem; */
    gap: .1rem;
}

.indicator-inactive:hover {
    background-color: white;
}

.indicator {
    background-color: white;
    height: 1px;
    width: 1px;
    border-radius: 100%;
    border: none;
    outline: none;

}

.indicator-inactive {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    background-color: lightgrey;
    border: none;
    outline: none;
}

@media only screen and (max-width: 440px){
    .review-container {
        padding: 0vh;
    }
    
    .c-review {
        text-align: center;
        padding: 1vh;
    }
    
    .c-reviews {
        padding: 1vh;
    }

    .c-review-top {
        left: 0rem;
        padding-bottom: 3vh;
    }

    .c-reviews-middle {
        padding: 1vh;
    }
}