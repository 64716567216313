.location {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7vh;
    justify-content: center;
    max-width: 1000px;
}

.location-paragraph {
    padding-right: 7vh;
    padding-bottom: 7vh;
}


#map {
    width: 100%;
}

@media only screen and (max-width: 440px){
    .location {
        padding: 3vh;
    }
}