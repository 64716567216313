@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&family=Rampart+One&family=Roboto+Mono:wght@100&family=Tenor+Sans&family=Tiro+Devanagari+Sanskrit&family=Titillium+Web:wght@300;400;700&family=Trocchi&display=swap');
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@300&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@700,300,400&f[]=gambetta@400,300,700&f[]=literata@200,400,700,900,300&display=swap");
* {
  font-family: 'Satoshi', sans-serif;
  margin: 0;
}

h3 {
  font-weight: 100;
}


.space {
  padding: 7vh;
}


.headline {
  /* text-align: center; */

}


#headline {
  padding-bottom: 20px;
  
}

#headline {
  color: black;
  max-width: 840px;

}


.top-headline {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-image: url('./images/main3big.webp');
  background-attachment: fixed;
  background-position: top center;
  background-size:cover;
  padding-bottom: 60vh;
  

  /* &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 40px;
    left: 0px;
    background-image: url('./images/main3.png');
    background-attachment: fixed;
    background-position: top center;
    background-size:cover;
    opacity: .5;
  } */
  
}

.kenburns-top {
	-webkit-animation: kenburns-top 5s ease-out both;
	        animation: kenburns-top 5s ease-out both;
}

@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

#hero-button {
  background-color: black;
  color: white;
  border-radius: 10px;
  opacity: .7;
  border: none;
  width: 280px;
  font-size: 25px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: transform .15s ease-in-out, box-shadow 0.25s ease-in-out;
}

#hero-button:hover {
  cursor: pointer;
  /* background-color: rgb(49, 49, 49); */
  transform: scale(1.01);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

#navbar3 {
  opacity: .9;
}

.top-headline-paragraph {
  position: relative;
  padding: 7vh 10vh 4vh 10vh;
  /* background-color: white; */
  /* text-shadow: white 1px .5px; */
  /* opacity: .7; */

}


#top-headline-paragraph {
  overflow: hidden;
  font-size: 65px;
}

@media only screen and (min-width: 808px) {
  #top-headline-paragraph2 {
    max-width: 550px;
  }
}

@media only screen and (max-width: 807px) {
  #top-headline-paragraph2 {
    max-width: 400px;
  }
}

@media only screen and (max-width: 560px) {
  #top-headline-paragraph2 {
    max-width: 200px;
  }
}


.mid-headline {
  padding: 5vh;
}

.focus {
  display: flex;
  justify-content: center;
  padding: 5vh;
  gap: 13vh;
}

@media only screen and (max-width: 1040px){
  .focus {
    flex-direction: column;
  }

  /* .focus1 {
    padding: 32vh;
  }

  .focus2 {
    padding: 32vh;
  } */


}

.mid-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0vh 7vh 7vh 7vh;
  gap: 60px;

}

.body1 {
  display: flex;
  padding-top: 40px;
  max-width: 1300px;
}

#body1-words {
  font-size: 45px;
  padding-bottom: 10px;

}

#body1-words2 {
  padding-bottom: 30px;
}

#learnmore-button {
  border-color: black;
  border-spacing: 10px;
  border-radius: 20px;
  padding: 20px;
  background-color: #49A2D5;
  text-shadow: none;
  color: white;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transition: transform .15s ease-in-out, box-shadow 0.25s ease-in-out;
}

#learnmore-button:hover {
  /* background-color: #5199c3; */
  transform: scale(1.01);
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.first-words {
  text-align: left;
}

.second-words {
  text-align: left;
}

.body2 {
  display: flex;
  max-width: 1300px;
}

#first {
  display: flex;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 8px black;
  /* animation: fadeIn 10s; */
}
.first {
  max-height: 50%;
  max-width: 50%;
  padding-right: 80px;
}
.first-words {
  max-height: 50%;
  max-width: 50%;
  padding-top: 40px;
  padding-bottom: 40px;
}
#second {
  display: flex;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 8px black;
}
.second {
  max-width: 50%;
  max-height: 50%;
}
.second-words {
  max-height: 50%;
  max-width: 50%;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
  
}

.focus1 {
  background-image: url('./images/sport2.jpg');
  background-size: cover;
  /* padding: 20vh; */
}

.focus2 {
  background-image: url('./images/pelvic.jpg');
  background-size: cover;
  /* padding: 20vh; */
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-headline {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  padding: 10vh 10vh 20vh 10vh;
  
}

.bottom-headline-words {
  text-align: center;
  max-width: 1000px;
}

#care {
  width: 80%;
  border-radius: 300px;
  box-shadow: 0px 0px 8px black;
}

.what-people-saying {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 60px;
  /* text-align: center; */
  margin-bottom: -1px;

}

#arrow-button-back {
  color: white;
}

#arrow-button-forward {
  color: white;
}

.custom-shape-divider-bottom-1694709272 {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  margin-top: -1px;
  margin-bottom: -1px;
}
.custom-shape-divider-bottom-1694709272 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 46px;
}
.custom-shape-divider-bottom-1694709272 .shape-fill {
  fill:#49A2D5;
}

.reviews-bottom-page {
  display: flex;
  flex-direction: column;
  background-color:#49A2D5;
  padding-bottom: 50px;
}

.more-reviews {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1px;
  margin-bottom: -1px;
  /* max-width: 1000px; */
}

#more-reviews {
  color: white;
}

#forward-arrow-2 {
  color: white;
  padding-top: 2px;
}

.white {
  display: flex;
  align-items: center;
  padding: 7vh;
  max-width: 1000px;
}
.reviews {
  padding: 5vh;
}
.slider-test {
  display: flex;
  align-items: center;
  justify-content: center;
}


.reviews {
  padding: 10vh;
}

#reviews {
  color: white;
}

#what {
  color: white;
}

.reviews-each {
  display: flex;
  gap: 60px;
}

.main-headline {
  text-align: center;
  padding: 3vh;
  background-color: #49A2D5;
  opacity: .85;
  
}

.profile-photo {
  display: flex;
  justify-content: center;
}

.body {
  display: flex;
  justify-content: center;
  background-color: white;
  height: 700px;
}

#pt {
  width: 100%;
}


.paragraph2 {
  display: flex;
  justify-content: center;
  padding: 3vh;
  background-color: grey;
  opacity: .85;
}

#paragraph3 {
  color: white;
}

.paragraph3 {
  max-width: 840px;
}



@media only screen and (max-width: 700px) {
  .body1 {
    flex-direction: column;
    align-items: center;
  }

  .first {
    padding-right: 0px;
  }

  .body2 {
    flex-direction: column;
    align-items: center;
  }

  .second-words {
    order: 2;
    padding-right: 0px;
  }


  .mid-section {
    padding: 0vh 0vh 5vh 0vh;
  }
}


@media only screen and (max-width: 1250px) {
  .bottom-headline {
    flex-direction: column;
    padding: 15vh 7vh 14vh 7vh;
  }
}


@media only screen and (max-width: 440px){
  
  .body {
    background-position: 20%;
  }

  .body1 {
    padding-top: 35px;
  }

  .top-headline {
    /* padding-bottom: 60vh; */
    background-image: url("./images/main3.webp");
    background-attachment:fixed;
    background-size: 900px;
    padding-bottom: 1vh;
  }


  .top-headline-paragraph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh 3vh 27vh 3vh;
  }

  #top-headline-paragraph {
    text-align: center;
    padding: 5vh 0vh 0vh 0vh;
    font-size: 30px;
  }

  #top-headline-paragraph2 {
    text-align: center;
    font-size: 23px;
    max-width: 250px;
    font-weight: 100;
  }

  #logo {
    font-size: 20px;
  }

  /* #icon {
    width: 20px;
  } */

  .space {
    padding: 1.5vh;
  }

  .main-headline {
    padding: 1vh;
  }

  #hero-button {
    width: 190px;
    font-size: 20px;
  }
  

  #headline {
    font-size: 20px;
    padding-bottom: 0px;
  }

  .bottom-headline {
    padding: 7vh 3vh 14vh 3vh;
  }

  .body {
    /* padding: 0vh 1vh 5vh 1vh; */
  }

  .headline {
    /* padding: 1vh; */
  }

  .first {
    max-width: 85%;
    max-height: 85%;
  }

  .first-words {
    max-width: 85%;
    max-height: 85%;
  }

  .second {
    max-width: 85%;
    max-height: 85%;
  }

  .second-words {
    max-width: 85%;
    max-height: 85%;
  }

  #body1-words {
    font-size: 35px;
  }

  #care {
    width: 100%;
  }

  .reviews {
    padding: 1vh;
  }

  .white {
    padding: 2vh;
  }

  #pt {
    width: 100%;
  }

  h1 {
    font-size: 22px;
  }

  #what {
    font-size: 28px;
  }

}


@media only screen and (min-width: 1300px) {
  .top-headline {
    padding-bottom: 70vh;
  }
}