.faq {
    display: flex;
    flex-direction: column;
    padding: 7vh;
    max-width: 1000px;
}

.faq-question {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
}

.faq-question:hover {
    cursor: pointer;
}

.faq-button {
    background-color: #777;
}

#button-box {
    border-radius: 20px;
}

#arrow-down {
    font-size: 30px;
}

.active, .faq-button:hover {

}

.faq-answer {
    padding: 0 18px;
    display: none;
    overflow: hidden;
}

@media only screen and (max-width: 440px){
    .faq {
        padding: 3vh 2vh 3vh 2vh;
    }

    .faq-question {
        align-items: flex-start;
    }
}
